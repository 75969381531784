import React from "react";
import './index.scss';
import WhyUseApp from "../../component/whyUseApp";
import numberWhy1 from "../../assets/numberWhy1.svg";
import numberWhy2 from "../../assets/numberWhy2.svg";
import numberWhy3 from "../../assets/numberWhy3.svg";
import numberWhy4 from "../../assets/numberWhy4.svg";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GetAppEasy from "../../component/getAppEasy";
const inforCrossSale = ({ linkInstall }) => {

    const easySetup = [
        'No coding required',
        'Integrate with theme & CSS',
        'Work with OS 2.0 themes'
    ]
    const fastSupport = [
        ' Available 24/7',
        'Live chat, email, calls, zoom',
        '1-1 personalized demo'
    ];

    const fitSeamlesslyInto = [
        'POS',
        'Online Store 2.0, Ajax cart',
        'Google calendar',
        'Mobile screen',
        'Subscriptions'
    ];

    const dataWhyUseApp = [
        {
            numberWhy: numberWhy1,
            title: 'Date & time slot for delivery,  pickup',
            textDescription: 'Configure different delivery/pickup date & time for individual days, products',
        },
        {
            numberWhy: numberWhy2,
            title: 'Distance validation',
            textDescription: "Check the customer's delivery eligibility right on the product page /cart page",
        },
        {
            numberWhy: numberWhy3,
            title: 'Limit orders',
            textDescription: 'Limit the number of deliveries per day/time slot during their peak time',
        },
        {
            numberWhy: numberWhy4,
            title: 'Order management',
            textDescription: 'A built-in calendar, Export all order data, Sync orders to Google calendar',
        }
    ]
    const dataWhyUseAppRender = dataWhyUseApp.map((element) =>
        <WhyUseApp
            numberWhy={element['numberWhy']}
            title={element['title']}
            textDescription={element['textDescription']}
        />);

    const dataGetAppEasy = [
        {
            title: 'Order management',
            textDescription: easySetup
        },
        {
            title: 'Fast support',
            textDescription: fastSupport
        },
        {
            title: 'Fit seamlessly into',
            textDescription: fitSeamlesslyInto
        }
    ]
    let contentNumber = 0;
    const getAppEasyReder = dataGetAppEasy.map((element) =>
        <div>
            {(contentNumber += 1) % 2 === 0 ?
                (
                    <div className="timeline-getapp-container getapp-left" content={contentNumber}>
                        <GetAppEasy
                            title={element['title']}
                            textDescription={element['textDescription']}
                        />
                    </div>
                ) : (
                    <div className="timeline-getapp-container getapp-right" content={contentNumber}>
                        <GetAppEasy
                            title={element['title']}
                            textDescription={element['textDescription']}
                        />
                    </div>
                )}

        </div>
    )
    return (

        <div className="infor-crossSale">
            <Row className="infor-crossSale-container">
                <Col lg={6} md={6} className="infor-crossSale-whyUseApp">
                    <p className="whyUseApp-title">Why DingDoong? </p>
                    {dataWhyUseAppRender}
                </Col>
                <Col lg={6} md={6} className="timeline-getapp">
                    <p className="timeline-getapp-title">Get the app easy for now</p>
                    {getAppEasyReder}
                </Col>
            </Row>
            <div className="infor-crossSale-btn">
                <a
                    href={linkInstall}
                    target="_blank"
                >
                    <button className="btn-discovered">

                        Discover the app
                    </button>
                </a>
            </div>
        </div>
    )
};
export default inforCrossSale;