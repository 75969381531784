import React from "react";
import meetour1 from "../../assets/meetourOverbaked.svg";
import meetour2 from "../../assets/meetourGreedy.svg";
import meetour3 from "../../assets/meetGiftmoments.svg";
import meetour4 from "../../assets/meetourFood.svg";
import meetour5 from "../../assets/meetourPies.svg";
import meetour6 from "../../assets/meetourSarahlu.svg";
import meetour7 from "../../assets/meetourFlower.svg";
import meetour8 from "../../assets/meetourSeoul.svg";
import './index.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../../src/bootstrap.min.css';

const meetOurClients = () => {
    return (
        <div className="meet-our-clients">
            <h3 className="meet-our-title">Meet Our Clients</h3>
            <p className="meet-our-info">We’ve helped thousands of businesses accelerate their growth</p>

            <Row className="meetour-container-top">
                <Col lg={3} md={4}>
                    <a href="https://180ovenbaked.com/" target="_blank">
                        <img
                            src={meetour1}
                            alt="link-img-meetour1"
                            className="meetour-container_img_text"
                        />
                    </a>

                </Col >
                <Col lg={3} md={4}>
                    <a href="https://greedygreedy.sg/password" target="_blank">
                        <img
                            src={meetour2}
                            alt="link-img-meetour2"
                            className="meetour-container_img"
                        />

                    </a>
                </Col>
                <Col lg={3} md={4}>
                    <a href="https://www.giftmoments.co.uk/" target="_blank">
                        <img
                            src={meetour3}
                            alt="link-img-meetour3"
                            className="meetour-container_img_text"
                        />
                    </a>
                </Col>
            </Row>
            <Row className="meetour-container-footer">
                <Col lg={2} md={4}>
                    <a href="https://chowboyfood.myshopify.com/" target="_blank">
                        <img
                            src={meetour4}
                            alt="link-img-meetour4"
                            className="meetour-container_img"
                        />
                    </a>
                </Col>
                <Col lg={2} md={3}>
                    <a href="https://www.simplygoodpies.com/" target="_blank">
                        <img
                            src={meetour5}
                            alt="link-img-meetour5"
                            className="meetour-container_img"
                        />
                    </a>
                </Col>
                <Col lg={2} md={3}>
                    <a href="https://sarahluconfections.com/" target="_blank">
                        <img
                            src={meetour6}
                            alt="link-img-meetour6"
                            className="meetour-container_img_text"
                        />
                    </a>
                </Col>
                <Col lg={2} md={3}>
                    <a href="https://rof.flowercompany.ca/" target="_blank">
                        <img
                            src={meetour7}
                            alt="link-img-meetour7"
                            className="meetour-container_img"
                        />
                    </a>
                </Col>
                <Col lg={2} md={3}>
                    <a href="https://seoulrecipe.com/" target="_blank">
                        <img
                            src={meetour8}
                            alt="link-img-meetour8"
                            className="meetour-container_img_text"
                        />
                    </a>
                </Col>
            </Row>

        </div >
    )
};
export default meetOurClients;