import React from "react";
import topHeader from "../../assets/topHeader.svg";
import './index.scss';
const header = ({ linkInstall }) => {
    return (
        <div className="header">
            <div className="top-header">
                <img
                    src={topHeader}
                    alt="link-img-topHeader"
                    className="top-header_img"
                />
            </div>
            <div className="header-button">
                <a
                    href={linkInstall}
                    target="_blank"
                >
                    <button>
                        Install now
                    </button>
                </a>
            </div>
        </div>
    )
};
export default header;