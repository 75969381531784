import React from "react";
import {
    Card
} from "@shopify/polaris";
import '@shopify/polaris/build/esm/styles.css';
import star from "../../assets/star.svg";

import './index.scss'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../../src/bootstrap.min.css';
const sliderReview = ({ sliderImgAuthor, sliderImgClient, textDescription, author, country }) => {
    return (
        <div className="slider-review">
            <Card>
                <div className="slider-info" >
                    <div className="slider-component">
                        <img
                            src={sliderImgAuthor}
                            alt="link-img-author"
                            className="slider-component-ImgAuthor"
                        />
                    </div>
                    <div className="slider-component">
                        <img
                            src={sliderImgClient}
                            alt="link-img-client"
                            className="slider-component-ImgClient"
                        />
                        <div className="card-component_star">
                            <img
                                src={star}
                                alt="link-icon-star"
                            />
                            <img
                                src={star}
                                alt="link-icon-star"
                            />
                            <img
                                src={star}
                                alt="link-icon-star"
                            />
                            <img
                                src={star}
                                alt="link-icon-star"
                            />
                            <img
                                src={star}
                                alt="link-icon-star"
                            />
                        </div>

                        <p className="slider-description"> {textDescription}</p>
                        <Row className="author-info">
                            <Col lg={7} >
                                <p className="text-author"> {author}</p>
                            </Col>
                            <Col lg={5}  >
                                <p className="text-country"> {country}</p>
                            </Col>

                        </Row>

                    </div>
                </div>
            </Card>
        </div>
    )
};
export default sliderReview;