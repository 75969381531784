import React from "react";
import iconFb from "../../assets/iconFb.svg";
import iconYtb from "../../assets/iconYtb.svg";
import iconIn from "../../assets/iconIn.svg";
import iconInta from "../../assets/iconInta.svg";
import iconTw from "../../assets/iconTw.svg";
import iconShop from "../../assets/iconShop.svg";
import './index.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../../src/bootstrap.min.css';

const keepInTouch = () => {
    const dataIcon = [
        {
            icon: iconFb,
            link: 'https://www.facebook.com/DingDoong-Delivery-Pickup-110058535114775/'
        },
        {
            icon: iconYtb,
            link: 'https://www.youtube.com/watch?v=gTSn5zhCJMA'
        },
        {
            icon: iconIn,
            link: 'https://www.linkedin.com/company/dingdoong/about/'
        },
        {
            icon: iconInta,
            link: 'https://www.instagram.com/dingdoong.io/'
        },
        {
            icon: iconTw,
            link: 'https://twitter.com/dingdoong_app'
        },
        {
            icon: iconShop,
            link: 'https://apps.shopify.com/partners/omegaapps'
        },
    ];
    const dataRender = dataIcon.map((element) =>
        <Col lg={2} md={2} sm={6}>
            <a href={element['link']} target="_blank">
                <img
                    src={element['icon']}
                    alt="link-img-keep-in"
                    className="keep-in-container_img"
                />
            </a>
        </Col>
    );
    return (

        <div className="keep-in-touch">
            <h3 className="keep-in-title">Keep in touch!</h3>
            <p className="keep-in-info">Keep follow us to get updates on the app and its upcoming features</p>
            <Row className="keep-in-container">
                {dataRender}
            </Row>
            <p className="keep-in-info">Don't hesitate to contact us.</p>
            <p className="keep-in-info keep-in-info-enter">There's always a room for improvement, we are all ear to any of your ideas.</p>
        </div >
    )
};
export default keepInTouch;