import logo from './logo.svg';
import './App.css';

import React, { useEffect, useState } from "react";
import HeaderCrossSale from '../src/layouts/headerCrossSale'
import InforCrossSale from '../src/layouts/inforCrossSale'
import MeetOurClients from '../src/layouts/meetOurClients'
import SliderCrossSale from '../src/layouts/sliderCrossSale'
import CountDownEvent from '../src/layouts/countDownEvent'
import KeepInTouch from '../src/layouts/keepInTouch'
import {
  AppProvider,

} from '@shopify/polaris';


function App() {
  const [linkInstall, setLinkInstall] = useState("");
  useEffect(() => {
    let getUrlParams = new URLSearchParams(window.location.search);
    let getLinkInstall = 'https://apps.shopify.com/delivery-date-omega?' + getUrlParams;
    setLinkInstall(getLinkInstall);
  }, []);

  const handleResize = () => {
    let zoomSize = Math.round(window.devicePixelRatio * 100)
    const existedStyle = document.getElementById("style-app");
    if (existedStyle) {
      existedStyle.remove();
    }
    console.log('zoomSize', zoomSize)
    const style = document.createElement("style");
    style.id = "style-app";

    if (zoomSize <= 25) {
      style.textContent = `
       .App {
             width:10% ;
             }`
    }
    else if (zoomSize > 25 && zoomSize <= 50) {
      style.textContent = `
       .App {
             width:20% ;
             }`
    }
    else if (zoomSize > 50 && zoomSize <= 90) {
      style.textContent = `
       .App {
             width:32% ;
             }`
    } else if (zoomSize > 100 && zoomSize < 250) {
      style.textContent = `
       .App {
             width:80% !important;
            }
        @media (min-width: 600px) and (max-width: 1024px) 
          {
             .header .header-button 
             {
             bottom: 10px !important;        
             }
          }  
          

             `
    }
    else if (zoomSize >= 250 && zoomSize < 400) {
      style.textContent = `
    .App {
             width:100% !important;
            }
        @media (min-width: 600px) and (max-width: 1024px) 
          {
             .header .header-button 
             {
             bottom: 10px !important;        
             }
          }  

             `
    }
    else if (zoomSize >= 250 && zoomSize < 300) {
      style.textContent = `
     

      @media (min-width: 600px) and (max-width: 1024px) {
        .header {
         height: 360px;
              }

       .header .header-button {
         bottom: 18px !important;
         left: 36.5%;
        }
     }  
      `
    }
    else if (zoomSize >= 400 && zoomSize < 500) {
      style.textContent = `
      .App {
            width: 100%;
           }

      @media (min-width: 342px) and (max-width: 900px) {
        .header {
         height: 360px;
              }

       .header .header-button {
         bottom: 160px !important;
         left: 40.5%;
        }
     }  
      `
    }

    document.body.appendChild(style);
  };
  window.addEventListener('resize', handleResize)
  return (
    <AppProvider>
      <div className="App">
        <HeaderCrossSale linkInstall={linkInstall} />
        <InforCrossSale linkInstall={linkInstall} />
        <MeetOurClients />
        <SliderCrossSale linkInstall={linkInstall} />
        <CountDownEvent linkInstall={linkInstall} />
        <KeepInTouch />
      </div>
    </AppProvider>
  );
}

export default App;
