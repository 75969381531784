import React from "react";
import {
    Card
} from "@shopify/polaris";
import '@shopify/polaris/build/esm/styles.css';
import './index.scss'
const whyUseApp = ({ numberWhy, title, textDescription }) => {
    return (
        <div className="why-use-app">
            <Card>
                <div className="card-info" >
                    <div className="card-component">
                        <img
                            src={numberWhy}
                            alt="link-img-number-why"
                            className="card-component-img"
                        />
                    </div>
                    <div className="card-component">
                        <p className="title"> {title}</p>
                        <p className="text-description"> {textDescription}</p>
                    </div>
                </div>
            </Card>
        </div>
    )
};
export default whyUseApp;