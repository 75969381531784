import React from "react";
import '@shopify/polaris/build/esm/styles.css';
import './index.scss'
const getAppEasy = ({ title, textDescription }) => {
    function TextDescriptionList(props) {
        const listItems = props.textList.map((val) =>
            <li className='text-info-getapp' key={val.toString()}>
                {val}
            </li>
        );
        return (
            <ul>{listItems}</ul>
        );
    }
    return (
        <div className="get-app-easy">
            <div className="get-app-easy-title" >
                <p>{title} </p>
            </div>
            <div className="get-app-easy-info" >
                <TextDescriptionList textList={textDescription} />
            </div>
        </div>
    )
};
export default getAppEasy;