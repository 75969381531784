import React from 'react';
import FlipCountdown from '@rumess/react-flip-countdown';
import {
    Layout,
    Stack,
} from '@shopify/polaris';
import limitedoffer from "../../assets/limitedoffer.svg";
import './index.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../../../src/bootstrap.min.css';
const countDownEvent = ({ linkInstall }) => {
    const nextDay = new Date().getTime() + 24 * 2 * 60 * 60 * 1000;
    const getEndDate = new Date(nextDay).toJSON().slice(0, 10).split('-').join('-') + " 00:00:00"
    return (
        <div className="count-down-event">
            <Row>
                <Col md={8}>
                    <Layout.Section>
                        <div className="left-stack">
                            <Stack vertical alignment="center">
                                <p className="stack-title">Offer ends in</p>
                                <div className="countdown__wrapper">
                                    <FlipCountdown
                                        dayTitle="Days"
                                        hourTitle="Hours"
                                        minuteTitle="Minutes"
                                        secondTitle="Seconds"
                                        titlePosition="bottom"
                                        size="small"
                                        theme="light"
                                        hideYear
                                        hideMonth
                                        endAt={getEndDate} // yyyy/mm/dd
                                    />
                                </div>

                            </Stack>
                        </div>
                    </Layout.Section>
                </Col>
                <Col md={4}>
                    <Layout.Section oneThird>
                        <div className="right-stack">
                            <Stack vertical alignment="center">
                                <img
                                    src={limitedoffer}
                                    alt="link-img-limitedoffer"
                                    className="right-stack-img"
                                />
                                <div className="infor-crossSale-btn">
                                    <a
                                        href={linkInstall}
                                        target="_blank"
                                    >
                                        <button className="btn-Install">
                                            Install now
                                        </button>
                                    </a>
                                </div>
                            </Stack>
                        </div>
                    </Layout.Section>
                </Col>
            </Row>
        </div>
    )
};
export default countDownEvent;