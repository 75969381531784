import React from "react";
import SliderReview from "../../component/sliderReview";
import slider11 from "../../assets/slider11.jpeg";
import slider12 from "../../assets/slider12.svg";
import slider2 from "../../assets/slider2.jpg";
import slider21 from "../../assets/slider21.svg";
import slider31 from "../../assets/slider31.jpg";
import slider33 from "../../assets/slider33.svg";
import slider41 from "../../assets/slider41.jpg";
import slider42 from "../../assets/slider42.svg";
import slider51 from "../../assets/slider51.jpg";
import slider52 from "../../assets/slider52.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '@shopify/polaris/build/esm/styles.css';
import './index.scss';
const sliderCrossSale = ({ linkInstall }) => {
    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 5000,
    };
    const dataSlider = [
        {
            sliderImgAuthor: slider11,
            sliderImgClient: slider12,
            textDescription: 'Great app for letting our customers select delivery date for our flower delivery service.Looks great on our site, simple to use, many options to suit any requirements and is fast loading.Support is fantastic, Anna is lovely, kind and very helpful.Highly recommended!',
            author: 'Ashley Morris',
            country: 'United Kingdom',
        },
        {
            sliderImgAuthor: slider2,
            sliderImgClient: slider21,
            textDescription: 'Excellent support from the folk at DingDong. Answered my question in a few minutes and then altered the app settings so that it now works really well for me. Good Work Folks!!',
            author: 'Mark Mottram',
            country: 'Singapore',
        },
        {
            sliderImgAuthor: slider31,
            sliderImgClient: slider33,
            textDescription: "Great app! Does exactly what I needed. Way easier to manage (as a non-programmer) than what Shopify recommends with the javascript calendar. Live chat support has been great too by helping me optimising the app's loading speed and custom styling to match my theme.",
            author: 'Andy Ward',
            country: 'United States',
        },
        {
            sliderImgAuthor: slider41,
            sliderImgClient: slider42,
            textDescription: "After searching through many tens of delivery date and time slot app, this app is the best. The best part is, it is free for now. Other apps may be free too but their customer service is non-existant. DinDoong's team members like Anna, Kathy, Mike and more are helpful with the very efficient support when I have questions. This app is highly recommended as their features are easy to use and supports detailed needs for shops.",
            author: 'Foodcrumz Pte Ltd',
            country: 'Singapore',
        },
        {
            sliderImgAuthor: slider51,
            sliderImgClient: slider52,
            textDescription: "Great App, very easy to use and has a lot of features. The developers are brilliant and got the appearance on the website looking great and on point. 5 Stars and great service!",
            author: 'Konstantinos Baronos',
            country: 'United Kingdom',
        },
    ]

    const dataSliderRender = dataSlider.map((element) =>
        <SliderReview
            sliderImgAuthor={element['sliderImgAuthor']}
            sliderImgClient={element['sliderImgClient']}
            textDescription={element['textDescription']}
            author={element['author']}
            country={element['country']}
        />);
    return (
        <div className="slider-review">
            <Slider {...settings}>
                {dataSliderRender}
            </Slider>
            <div className="infor-crossSale-btn">
                <a
                    href={linkInstall}
                    target="_blank"
                >
                    <button className="btn-become">
                        Become our clients
                    </button>
                </a>
            </div>
        </div>
    )
};
export default sliderCrossSale;